<bit-dialog dialogSize="small" background="alt">
  <span bitDialogTitle>
    {{ "passwordHistory" | i18n }}
  </span>
  <ng-container bitDialogContent>
    <vault-password-history-view [cipherId]="cipherId" />
  </ng-container>
  <ng-container bitDialogFooter>
    <button bitButton (click)="close()" buttonType="primary" type="button">
      {{ "close" | i18n }}
    </button>
  </ng-container>
</bit-dialog>
